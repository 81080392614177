import React from 'react';
import { Button, Typography, Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Title } = Typography;
const { Content } = Layout;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #121212;
`;

const BackgroundEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
`;

const Light = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(100px);
  opacity: 0.3;
`;

const Light1 = styled(Light)`
  background: #FF6600;
  top: -150px;
  left: -150px;
  animation: float 20s infinite ease-in-out;
`;

const Light2 = styled(Light)`
  background: #FFA500;
  bottom: -150px;
  right: -150px;
  animation: float 25s infinite ease-in-out reverse;
`;

const Light3 = styled(Light)`
  background: #FF4500;
  top: 50%;
  left: 50%;
  animation: pulse 15s infinite ease-in-out;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
`;

const StyledTitle = styled(Title)`
  color: #FFFFFF !important;
  margin-bottom: 1rem !important;
`;

const StyledSubtitle = styled(Title)`
  color: #FF6600 !important;
  margin-bottom: 2rem !important;
`;

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #FF6600 0%, #FF8C00 100%);
  border-color: #FF6600;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  height: auto;
  padding: 10px 30px;

  &:hover, &:focus {
    background: linear-gradient(90deg, #FF8C00 0%, #FFA500 100%);
    border-color: #FF8C00;
    color: white;
  }
`;

export function HomePage() {
  return (
    <StyledContent>
      <BackgroundEffect>
        <Light1 />
        <Light2 />
        <Light3 />
      </BackgroundEffect>
      <ContentWrapper>
        <StyledTitle level={1}>Welcome to Fraktion</StyledTitle>
        <StyledSubtitle level={3}>Your Platform For Fractionalized BRC-20 Assets</StyledSubtitle>
        <Link to="/app">
          <StyledButton type="primary" size="large">
            Launch App
          </StyledButton>
        </Link>
      </ContentWrapper>
    </StyledContent>
  );
}

export default HomePage;