import React from 'react';
import { Result } from 'antd';

interface UnderConstructionProps {
  pageName: string;
}

export const UnderConstruction: React.FC<UnderConstructionProps> = ({ pageName }) => {
  return (
    <Result
      title={`${pageName} Page Under Construction`}
      subTitle="Sorry, this page is still being developed. Please check back later!"
    />
  );
};

export default UnderConstruction;