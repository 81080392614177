export const config = {
    NETWORK: 'FRACTAL_BITCOIN_MAINNET',
    CURRENCY: 'FB',
    CURRENCY_DECIMALS: 8,
    EXPLORER_URL: 'https://explorer.unisat.io/', 
    API_URL: process.env.REACT_APP_API_URL || 'https://fraktion.pro/api',
    FRACTAL_API_URL: process.env.REACT_APP_FRACTAL_API_URL || 'https://open-api-fractal.unisat.io',
    MAGIC_EDEN_API_URL: 'https://fraktion.pro/api', 
    DEFAULT_TOKENS: ['FraKtion', 'FraktPuppets'],
    UNISAT_MIN_FREE_AMOUNT: 5000000, // 0.05 FB in satoshis
    UNISAT_FEE_RATE: 0.005, // 0.5%
};

export const formatFB = (amount: number): string => {
    return (amount / 100000000).toFixed(config.CURRENCY_DECIMALS);
};

export const parseFB = (amount: string): number => {
    return Math.floor(parseFloat(amount) * 100000000);
};