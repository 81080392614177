export const formatSatsToFB = (satoshis: number, decimals: number = 4): string => {
  return (satoshis / 100000000).toFixed(decimals);
};

export const formatPrice = (price: number, decimals: number = 2): string => {
  return price.toFixed(decimals);
};

export const formatUnitPrice = (satoshis: number): string => {
  return satoshis.toLocaleString();
};

export const calculateTotalPrice = (quantity: number, unitPriceInSatoshis: number, decimals: number = 4): string => {
  const totalFB = (quantity * unitPriceInSatoshis) / 100000000;
  return formatPrice(totalFB, decimals);
};

export const calculateTotalFB = (amounts: number[], decimals: number = 4): string => {
  const total = amounts.reduce((sum, amount) => sum + amount, 0);
  return formatSatsToFB(total, decimals);
};

export const formatUSDPrice = (satoshis: number, btcPrice: number): string => {
  const usdPrice = (satoshis / 100000000) * btcPrice;
  return usdPrice.toFixed(2);
};

export const formatBTCPrice = (satoshis: string | number | undefined): string => {
  if (satoshis === undefined) return 'N/A';
  const btc = Number(satoshis) / 100000000;
  return btc.toFixed(8);
};

export const formatBTCVolume = (satoshis: string | number | undefined): string => {
  if (satoshis === undefined) return 'N/A';
  const btc = Number(satoshis) / 100000000;
  if (btc >= 1000) {
    return (btc / 1000).toFixed(2) + 'K BTC';
  }
  return btc.toFixed(2) + ' BTC';
};

export const formatLargeNumber = (value: string | number | undefined): string => {
  if (value === undefined) return 'N/A';
  const num = Number(value);
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + 'B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + 'K';
  }
  return num.toString();
};