import React, { useCallback, useEffect, useState } from "react";
import { Brc20InscriptionsItem, brc20Api, AddressBrc20Balance } from "../utils/brc20Api";
import { Button, Card, Col, Row, Statistic, Modal, InputNumber, Spin, Tabs, Typography, Tooltip, message } from "antd";
import { useUnisat } from "../provider/UniSatProvider";
import { handleError } from "../utils/utils";
import { marketApi, InscriptionType, BRC20ListItem } from "../utils/marketApi";
import { TagOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { formatSatsToFB } from "../utils/formatters";
import UpdatePriceModal from "./UpdatePriceModal";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

interface ExtendedBrc20InscriptionsItem extends Brc20InscriptionsItem {
    isPutOn: boolean;
    auctionId?: string;
    price?: number;
}

export function Assets({ tick }: { tick: string }) {
    const { address, signPsbt, getPublicKey, inscribeTransfer } = useUnisat();
    const [brc20Balance, setBrc20Balance] = useState<AddressBrc20Balance | null>(null);
    const [transferableInscriptions, setTransferableInscriptions] = useState<ExtendedBrc20InscriptionsItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listItem, setListItem] = useState<ExtendedBrc20InscriptionsItem | null>(null);
    const [updatePriceItem, setUpdatePriceItem] = useState<BRC20ListItem | null>(null);
    const [unitPrice, setUnitPrice] = useState<string>('');
    const [inscribeAmount, setInscribeAmount] = useState<string>('');
    const [isInscribing, setIsInscribing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const fetchAssetInfo = useCallback(async () => {
        if (!address) return;
        setIsLoading(true);
        setErrorMessage(null);
        try {
            const balanceInfo = await brc20Api.getAddressBrc20Summary(address);
            const tickBalance = balanceInfo.detail.find(item => item.ticker.toLowerCase() === tick.toLowerCase());
            setBrc20Balance(tickBalance || null);

            const transferable = await brc20Api.getAddressTransferable({
                address,
                tick,
                start: 0,
                limit: 200,
            });

            const listedItems = await marketApi.listBrc20({
                filter: { address, tick, nftType: InscriptionType.brc20, isEnd: false },
                sort: {},
                start: 0,
                limit: 100, // Reduced to a safer limit
            });

            const extendedTransferable = transferable.detail.map(item => {
                const listedItem = listedItems.list.find(listed => listed.inscriptionId === item.inscriptionId);
                return {
                    ...item,
                    isPutOn: !!listedItem,
                    auctionId: listedItem?.auctionId,
                    price: listedItem?.price,
                } as ExtendedBrc20InscriptionsItem;
            });

            setTransferableInscriptions(extendedTransferable);
        } catch (error) {
            console.error('Error fetching asset info:', error);
            setErrorMessage('Failed to fetch asset information. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, [address, tick]);

    useEffect(() => {
        fetchAssetInfo();
    }, [fetchAssetInfo]);

    const handleInscribe = async () => {
        if (!inscribeAmount) {
            message.error('Please enter an amount to inscribe');
            return;
        }
        setIsInscribing(true);
        try {
            await inscribeTransfer(tick, inscribeAmount);
            message.success('Inscription successful');
            fetchAssetInfo();
            setInscribeAmount('');
        } catch (error) {
            handleError(error);
        } finally {
            setIsInscribing(false);
        }
    };

    const handleList = async () => {
        if (!listItem) return;
        setIsLoading(true);
        try {
            const pubkey = await getPublicKey();
            const totalPrice = (Number(listItem.data.amt) * Number(unitPrice)).toFixed(8);
            
            const { auctionId, psbt } = await marketApi.createPutOn({
                inscriptionId: listItem.inscriptionId,
                nftType: InscriptionType.brc20,
                initPrice: totalPrice,
                marketType: 'fixedPrice',
                pubkey,
                unitPrice,
            });

            const signedPsbt = await signPsbt(psbt);

            await marketApi.confirmPutOn({
                auctionId,
                psbt: signedPsbt,
            });

            Modal.success({
                title: 'Success',
                content: 'Item listed successfully',
            });
            setListItem(null);
            setUnitPrice('');
            fetchAssetInfo();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelist = async (item: ExtendedBrc20InscriptionsItem) => {
        if (!item.auctionId) {
            Modal.error({
                title: 'Error',
                content: 'Cannot delist item without an auction ID',
            });
            return;
        }
        setIsLoading(true);
        try {
            const putOffRes = await marketApi.createPutOff({ auctionId: item.auctionId });
            const signedPsbt = await signPsbt(putOffRes.psbt);
            await marketApi.confirmPutOff({
                auctionId: item.auctionId,
                psbt: signedPsbt,
            });
            Modal.success({
                title: 'Success',
                content: 'Item delisted successfully',
            });
            fetchAssetInfo();
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdatePrice = (item: ExtendedBrc20InscriptionsItem) => {
        const brc20ListItem: BRC20ListItem = {
            auctionId: item.auctionId || '',
            inscriptionId: item.inscriptionId,
            inscriptionNumber: item.inscriptionNumber,
            inscriptionName: `${tick}_${item.data.amt}`,
            marketType: 'fixedPrice',
            nftType: InscriptionType.brc20,
            initPrice: item.price || 0,
            curPrice: item.price || 0,
            minBidPrice: item.price || 0,
            endTime: 0,
            address: address || '',
            onSaleTime: 0,
            price: item.price || 0,
            tick: tick,
            limit: Number(item.data.decimal),
            amount: Number(item.data.amt),
            unitPrice: item.price ? item.price / Number(item.data.amt) : 0,
        };
        setUpdatePriceItem(brc20ListItem);
    };

    const handleUpdatePriceSuccess = useCallback(() => {
        setUpdatePriceItem(null);
        fetchAssetInfo();
    }, [fetchAssetInfo]);

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <div style={{ color: '#FFFFFF' }}>
            <Title level={3} style={{ color: '#FFFFFF' }}>{tick.toUpperCase()} Assets</Title>
            {errorMessage && (
                <div style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</div>
            )}
            <Tabs defaultActiveKey="1">
                <TabPane tab="Available Balance" key="1">
                    <Card style={{ background: '#252525', border: '1px solid #808080' }}>
                        <Statistic
                            title="Available Balance"
                            value={brc20Balance?.availableBalance || "0"}
                            suffix={tick.toUpperCase()}
                            valueStyle={{ color: '#FF6600' }}
                        />
                        <Statistic
                            title="Transferable Balance"
                            value={brc20Balance?.transferableBalance || "0"}
                            suffix={tick.toUpperCase()}
                            valueStyle={{ color: '#FF6600' }}
                            style={{ marginTop: 16 }}
                        />
                        <Tooltip title="Inscribe your available balance to make it transferable">
                            <Button
                                type="primary"
                                onClick={() => setInscribeAmount(brc20Balance?.availableBalance || "0")}
                                style={{ marginTop: 16 }}
                            >
                                Inscribe All
                            </Button>
                        </Tooltip>
                    </Card>
                    <Card style={{ marginTop: 16 }}>
                        <Title level={5}>Inscribe Custom Amount</Title>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={inscribeAmount}
                            onChange={(value) => setInscribeAmount(value?.toString() || '')}
                            max={brc20Balance?.availableBalance || "0"}
                            addonAfter={tick.toUpperCase()}
                        />
                        <Button
                            type="primary"
                            onClick={handleInscribe}
                            loading={isInscribing}
                            style={{ marginTop: 16 }}
                        >
                            Inscribe
                        </Button>
                    </Card>
                </TabPane>
                <TabPane tab="Transferable Inscriptions" key="2">
                    <Row gutter={[16, 16]}>
                        {transferableInscriptions.map((item) => (
                            <Col key={item.inscriptionId} xs={24} sm={12} md={8} lg={6}>
                                <Card
                                    hoverable
                                    style={{ height: '100%', overflow: 'hidden' }}
                                    actions={item.isPutOn ? [
                                        <Tooltip title="Delist">
                                            <Button
                                                icon={<StopOutlined />}
                                                onClick={() => handleDelist(item)}
                                            />
                                        </Tooltip>,
                                        <Tooltip title="Update Price">
                                            <Button
                                                icon={<EditOutlined />}
                                                onClick={() => handleUpdatePrice(item)}
                                            />
                                        </Tooltip>
                                    ] : [
                                        <Tooltip title="List for sale">
                                            <Button
                                                icon={<TagOutlined />}
                                                onClick={() => setListItem(item)}
                                            >
                                                List
                                            </Button>
                                        </Tooltip>
                                    ]}
                                >
                                    <Statistic
                                        title="Amount"
                                        value={item.data.amt}
                                        suffix={tick.toUpperCase()}
                                        valueStyle={{ fontSize: '16px' }}
                                    />
                                    <Text type="secondary" ellipsis={{ tooltip: item.inscriptionId }} style={{ fontSize: '12px' }}>
                                        ID: {item.inscriptionId.slice(0, 8)}...
                                    </Text>
                                    {item.isPutOn && item.price && (
                                        <Statistic
                                            title="Listed Price"
                                            value={formatSatsToFB(item.price)}
                                            suffix="FB"
                                            valueStyle={{ fontSize: '14px' }}
                                        />
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </TabPane>
            </Tabs>

            <Modal
                title="List Item for Sale"
                visible={!!listItem}
                onOk={handleList}
                onCancel={() => setListItem(null)}
                confirmLoading={isLoading}
            >
                {listItem && (
                    <>
                        <Statistic title="Amount" value={listItem.data.amt} suffix={tick.toUpperCase()} />
                        <InputNumber
                            style={{ width: '100%', marginTop: 16 }}
                            value={unitPrice}
                            onChange={(value) => setUnitPrice(value?.toString() || '')}
                            addonAfter={`sats/${tick.toUpperCase()}`}
                            placeholder="Enter unit price in sats"
                        />
                        <Statistic 
                            title="Total Price" 
                            value={formatSatsToFB((Number(listItem.data.amt) * Number(unitPrice)))} 
                            suffix="FB"
                            style={{ marginTop: 16 }}
                        />
                    </>
                )}
            </Modal>

            <UpdatePriceModal
                isOpen={!!updatePriceItem}
                onClose={() => setUpdatePriceItem(null)}
                item={updatePriceItem}
                onSuccess={handleUpdatePriceSuccess}
            />
        </div>
    );
}

export default Assets;