import { handleError } from "./utils";
import { config } from "./config";

export const unisatUtils = {
  getAccounts: async (): Promise<string[]> => {
    try {
      await window.unisat.switchChain(config.NETWORK);
      const accounts = await window.unisat.getAccounts();
      return accounts;
    } catch (e: any) {
      handleError(e);
      return [];
    }
  },

  requestAccounts: async (): Promise<string[]> => {
    try {
      await window.unisat.switchChain(config.NETWORK);
      const accounts = await window.unisat.requestAccounts();
      return accounts;
    } catch (e: any) {
      handleError(e);
      return [];
    }
  },

  signMessage: (message: string, type?: string): Promise<string> => {
    return window.unisat.signMessage(message, type);
  },

  signPsbt: (psbt: string): Promise<string> => {
    return window.unisat.signPsbt(psbt, { autoFinalized: false });
  },

  getPublicKey: async (): Promise<string> => {
    try {
      return await window.unisat.getPublicKey();
    } catch (e: any) {
      handleError(e);
      return '';
    }
  },

  getBalance: async (): Promise<{ confirmed: number; unconfirmed: number; total: number }> => {
    try {
      return await window.unisat.getBalance();
    } catch (e: any) {
      handleError(e);
      return { confirmed: 0, unconfirmed: 0, total: 0 };
    }
  },

  inscribeTransfer: (tick: string, amount?: number | string) => {
    return window.unisat.inscribeTransfer(tick, amount);
  },

  getInscriptions: async (cursor: number, size: number) => {
    try {
      return await window.unisat.getInscriptions(cursor, size);
    } catch (e: any) {
      handleError(e);
      return { total: 0, list: [] };
    }
  },

  sendBitcoin: async (toAddress: string, satoshis: number, options?: { feeRate?: number }) => {
    try {
      return await window.unisat.sendBitcoin(toAddress, satoshis, options);
    } catch (e: any) {
      handleError(e);
      throw e;
    }
  },

  sendInscription: async (address: string, inscriptionId: string, options?: { feeRate?: number }) => {
    try {
      return await window.unisat.sendInscription(address, inscriptionId, options);
    } catch (e: any) {
      handleError(e);
      throw e;
    }
  },
};