import React, { useState, useEffect, useCallback } from 'react';
import { Space, Typography, Tooltip, Divider, } from 'antd';
import axios from 'axios';

const { Text } = Typography;

const PRICE_CACHE_DURATION = 5 * 60 * 1000; // 15 minutes for crypto prices
const PRICE_UPDATE_INTERVAL = 5 * 60 * 1000; // 15 minutes for crypto prices
const FEES_CACHE_DURATION = 1 * 60 * 1000; // 1 minute for mempool fees
const FEES_UPDATE_INTERVAL = 1 * 60 * 1000; // 1 minute for mempool fees

interface CachedData {
  data: any;
  timestamp: number;
}

const InfoBar = () => {
  const [bitcoinPrice, setBitcoinPrice] = useState<number | null>(null);
  const [fbPrice, setFbPrice] = useState<number | null>(null);
  const [fees, setFees] = useState<{ fastestFee: number } | null>(null);

  const getCachedData = useCallback((key: string): CachedData | null => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    return null;
  }, []);

  const setCachedData = useCallback((key: string, data: any) => {
    const cacheData: CachedData = {
      data: data,
      timestamp: Date.now()
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  }, []);

  const fetchPrices = useCallback(async (forceUpdate: boolean = false) => {
    try {
      const cachedPrices = getCachedData('cryptoPrices');
      if (!forceUpdate && cachedPrices && (Date.now() - cachedPrices.timestamp < PRICE_CACHE_DURATION)) {
        setBitcoinPrice(cachedPrices.data.bitcoin.usd);
        setFbPrice(cachedPrices.data.fb.usd);
      } else {
        const response = await axios.get('/api/crypto-prices');
        setBitcoinPrice(response.data.data.BTC[0].quote.USD.price);
        setFbPrice(response.data.data.FB[0].quote.USD.price);
        setCachedData('cryptoPrices', {
          bitcoin: { usd: response.data.data.BTC[0].quote.USD.price },
          fb: { usd: response.data.data.FB[0].quote.USD.price }
        });
      }
    } catch (error) {
      console.error('Error fetching price data:', error);
    }
  }, [getCachedData, setCachedData]);
  
  const fetchFees = useCallback(async (forceUpdate: boolean = false) => {
    try {
      const cachedFees = getCachedData('mempoolFees');
      if (!forceUpdate && cachedFees && (Date.now() - cachedFees.timestamp < FEES_CACHE_DURATION)) {
        setFees(cachedFees.data);
      } else {
        const response = await axios.get('/api/mempool-fees');
        setFees(response.data);
        setCachedData('mempoolFees', response.data);
      }
    } catch (error) {
      console.error('Error fetching fee data:', error);
    }
  }, [getCachedData, setCachedData]);

  useEffect(() => {
    fetchPrices();
    fetchFees();

    const priceInterval = setInterval(() => fetchPrices(true), PRICE_UPDATE_INTERVAL);
    const feesInterval = setInterval(() => fetchFees(true), FEES_UPDATE_INTERVAL);

    return () => {
      clearInterval(priceInterval);
      clearInterval(feesInterval);
    };
  }, [fetchPrices, fetchFees]);

  return (
    <Space split={<Divider type="vertical" />} style={{ padding: '5px', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '5px' }}>
      <Tooltip title="Bitcoin Price">
        <Space>
          <img src="/images/bitcoin-logo.png" alt="BTC" style={{ width: '16px', height: '16px' }} />
          <Text style={{ fontSize: '12px' }}>${bitcoinPrice?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 'N/A'}</Text>
        </Space>
      </Tooltip>
      <Tooltip title="Fractal Bitcoin Price">
        <Space>
          <img src="/images/fb-logo.png" alt="FB" style={{ width: '16px', height: '16px' }} />
          <Text style={{ fontSize: '12px' }}>${fbPrice?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) ?? 'N/A'}</Text>
        </Space>
      </Tooltip>
      <Tooltip title="Transaction Fee">
        <Text style={{ fontSize: '12px' }}>Fee: {fees?.fastestFee ?? 'N/A'} sat/vB</Text>
      </Tooltip>
    </Space>
  );
};

export default InfoBar;