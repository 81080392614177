import React, { useCallback, useEffect, useState } from "react";
import { BRC20ListItem, InscriptionType, marketApi } from "../utils/marketApi";
import { Table, Button, Spin, message, Tooltip } from "antd";
import { BuyConfirmAlert } from "../components/BuyConfirmAlert";
import { useUnisat } from "../provider/UniSatProvider";
import UpdatePriceModal from "./UpdatePriceModal";
import { formatPrice, formatUnitPrice, calculateTotalPrice } from "../utils/formatters";

const PAGE_SIZE = 10;

export function Listed({ tick }: { tick: string }) {
    const { address, signPsbt } = useUnisat();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [list, setList] = useState<BRC20ListItem[]>([]);
    const [buyItem, setBuyItem] = useState<BRC20ListItem | undefined>();
    const [updatePriceItem, setUpdatePriceItem] = useState<BRC20ListItem | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchListings = useCallback(async () => {
        setLoading(true);
        try {
            const listingsRes = await marketApi.listBrc20({
                filter: {
                    tick,
                    nftType: InscriptionType.brc20,
                    isEnd: false
                },
                sort: { unitPrice: 1 },
                start: (page - 1) * PAGE_SIZE,
                limit: PAGE_SIZE
            });

            setTotal(listingsRes.total);
            setList(listingsRes.list);
        } catch (error) {
            console.error('Error fetching listings:', error);
            message.error('Failed to fetch listings');
        } finally {
            setLoading(false);
        }
    }, [page, tick]);

    useEffect(() => {
        fetchListings();
    }, [fetchListings]);

    const handleBuyComplete = useCallback(() => {
        setBuyItem(undefined);
        fetchListings();
    }, [fetchListings]);

    const handleUnlist = useCallback(async (item: BRC20ListItem) => {
        try {
            setLoading(true);
            const putOffRes = await marketApi.createPutOff({ auctionId: item.auctionId });
            const signedPsbt = await signPsbt(putOffRes.psbt);
            await marketApi.confirmPutOff({
                auctionId: item.auctionId,
                psbt: signedPsbt,
            });
            message.success('Item unlisted successfully');
            fetchListings();
        } catch (error) {
            console.error('Error unlisting item:', error);
            message.error(`Failed to unlist item: ${error instanceof Error ? error.message : 'Unknown error'}`);
        } finally {
            setLoading(false);
        }
    }, [fetchListings, signPsbt]);

    const handleUpdatePriceSuccess = useCallback(() => {
        setUpdatePriceItem(null);
        fetchListings();
    }, [fetchListings]);

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => `${amount.toLocaleString()} ${tick}`
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (price: number) => (
                <Tooltip 
                    title={`${formatPrice(price / 100000000)} FB`}
                    color="#1f1f1f"
                    overlayInnerStyle={{ color: '#FF6600' }}
                >
                    <span>{formatUnitPrice(price)}</span>
                </Tooltip>
            )
        },
        {
            title: 'Total Price',
            dataIndex: 'price',
            key: 'price',
            render: (price: number, record: BRC20ListItem) => (
                <Tooltip 
                    title={`${formatPrice(price / 100000000)} FB`}
                    color="#1f1f1f"
                    overlayInnerStyle={{ color: '#FF6600' }}
                >
                    <span>{calculateTotalPrice(record.amount, record.unitPrice)} FB</span>
                </Tooltip>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: BRC20ListItem) => (
                <>
                    {record.address === address ? (
                        <>
                            <Button onClick={() => handleUnlist(record)} style={{ marginRight: 8 }}>
                                Unlist
                            </Button>
                            <Button onClick={() => setUpdatePriceItem(record)}>
                                Update Price
                            </Button>
                        </>
                    ) : (
                        <Button type="primary" onClick={() => setBuyItem(record)}>
                            Buy
                        </Button>
                    )}
                </>
            )
        }
    ];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={list}
                rowKey="auctionId"
                pagination={{
                    total,
                    pageSize: PAGE_SIZE,
                    onChange: setPage,
                    current: page,
                    style: { color: '#FFFFFF' }
                }}
                className="custom-table"
            />
            {buyItem && (
                <BuyConfirmAlert
                    item={buyItem}
                    close={() => setBuyItem(undefined)}
                    onComplete={handleBuyComplete}
                />
            )}
            <UpdatePriceModal
                isOpen={!!updatePriceItem}
                onClose={() => setUpdatePriceItem(null)}
                item={updatePriceItem}
                onSuccess={handleUpdatePriceSuccess}
            />
        </>
    );
}

export default Listed;