import React, { useState, useEffect } from 'react';
import { Table, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './TickerTable.css';

interface TickerData {
  key: string;
  ticker: string;
  floorPrice: number;
  totalSupply: string;
  icon: string;
}

const API_URL = 'https://www.fraktion.pro/api';


const formatPrice = (price: number): string => {
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

const columns = [
  {
    title: 'Ticker',
    dataIndex: 'ticker',
    key: 'ticker',
    render: (text: string, record: TickerData) => (
      <Link to={`/app/token/${text}`} className="ticker-link">
        <div className="ticker-icon">
          <img src={record.icon} alt={`${text} icon`} />
        </div>
        {text}
      </Link>
    ),
  },
  
  {
    title: 'Floor Price (sats FB)',
    dataIndex: 'floorPrice',
    key: 'floorPrice',
    render: (price: number) => `${formatPrice(price)} sats`,
  },
  {
    title: 'Total Supply',
    dataIndex: 'totalSupply',
    key: 'totalSupply',
    render: (text: string) => Number(text).toLocaleString(),
  },
];

export const TickerTable: React.FC = () => {
  const [data, setData] = useState<TickerData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const tickers = ['FraKtion', 'FraktPuppets'];
        const tickerData: TickerData[] = [];

        for (const ticker of tickers) {
          try {
            const [infoResponse, priceResponse] = await Promise.all([
              axios.get(`${API_URL}/v1/indexer/brc20/${ticker}/info`),
              axios.post(`${API_URL}/v3/market/brc20/auction/brc20_types_specified`, {
                timeType: 'day1',
                tick: ticker,
              }),
            ]);

            if (infoResponse.data.code !== 0 || priceResponse.data.code !== 0) {
              throw new Error(`Failed to fetch data for ${ticker}`);
            }

            const info = infoResponse.data.data;
            const priceInfo = priceResponse.data.data;
            const floorPrice = Number(priceInfo.curPrice);

            tickerData.push({
              key: ticker,
              ticker: ticker,
              floorPrice: floorPrice,
              totalSupply: info.totalMinted,
              icon: `/images/${ticker}-icon.png`,
            });
          } catch (error) {
            console.error(`Error fetching data for ${ticker}:`, error);
            message.error(`Unable to load data for ${ticker}`);
          }
        }

        setData(tickerData);
      } catch (error) {
        console.error('Error fetching data:', error);
        message.error('Error loading data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="ticker-table-container">
      <Table 
        columns={columns} 
        dataSource={data} 
        pagination={false}
        scroll={{ y: 'calc(100vh - 150px)' }}
        rowKey="key"
        className="ticker-table"
      />
    </div>
  );
};

export default TickerTable;