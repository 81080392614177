import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout, Typography, Tabs, Card, Statistic, Spin, Table, Tag, Image, Button, Row, Col, Tooltip, Divider } from 'antd';
import { ShoppingOutlined, WalletOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Listed } from './Listed';
import { Assets } from './Assets';
import { useUnisat } from '../provider/UniSatProvider';
import { marketApi, Brc20Info, ActionItem } from '../utils/marketApi';
import { formatBTCPrice, formatLargeNumber, formatSatsToFB, formatBTCVolume } from '../utils/formatters';
import styled from 'styled-components';

const { Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

// Styled components
const GlowingCard = styled(Card)`
  background: rgba(255, 102, 0, 0.1);
  border-radius: 20px;
  border: 1px solid rgba(255, 102, 0, 0.2);
  box-shadow: 0 0 15px rgba(255, 102, 0, 0.3);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 30px rgba(255, 102, 0, 0.5);
    transform: translateY(-5px);
  }
`;

const PulsingStatistic = styled(Statistic)`
  .ant-statistic-content-value {
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ShimmeringText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  background: linear-gradient(90deg, #FF6600 0%, #FFA500 50%, #FF6600 100%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
  display: block;
  text-align: left;
  margin-bottom: 16px;
  
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #FF6600 0%, #FF8C00 100%);
  border-color: #FF6600;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover, &:focus {
    background: linear-gradient(90deg, #FF8C00 0%, #FFA500 100%);
    border-color: #FF8C00;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 102, 0, 0.4);
  }
`;

const FRACTIONALIZED_TOKENS = ['FraktPuppets'];

const assetDetails: { [key: string]: { multisigAddress: string } } = {
  'FraktPuppets': {
    multisigAddress: 'bc1phkmrtl3g6lxjzuvernwjydlqls6rqfvfwn9mcdtk62jnz9qktzxqgngsx4'
  },
};

const tickerImageMap: { [key: string]: string } = {
  'FraktPuppets': '/images/bitcoin-puppets01.jpeg',
  'FraKtion': '/images/frakt.jpg'
};

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export function TokenDetailPage() {

  const { tick } = useParams<{ tick: string }>();
  const { isConnected } = useUnisat();
  const [tokenData, setTokenData] = useState<Brc20Info | null>(null);
  const [actions, setActions] = useState<ActionItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [magicEdenStats, setMagicEdenStats] = useState<any>(null);

  const getImageSize = () => {
    return tick === 'FraKtion' ? '150px' : '200px';
  };
  
  const fetchData = useCallback(async () => {
    if (!tick) return;
    setLoading(true);
    setError(null);
    try {
      const [tokenInfo, actionsData] = await Promise.all([
        marketApi.getBrc20Info(tick),
        marketApi.getActions({
          filter: { tick, nftType: 'brc20' },
          start: 0,
          limit: 50
        })
      ]);
  
      setTokenData(tokenInfo);
      setActions(actionsData?.list || []);
  
      if (FRACTIONALIZED_TOKENS.includes(tick)) {
        const meStats = await marketApi.getCollectionStats('bitcoin-puppets');
        setMagicEdenStats(meStats);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [tick]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatAddress = (address: string | undefined) => {
    if (!address) return 'Unknown';
    return `${address.substring(0, 6)}...${address.substring(address.length - 6)}`;
  };

  const actionColumns = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render: (text: string) => (
        <Tag color={text === 'Listed' ? 'green' : text === 'Sold' ? 'blue' : 'orange'}>
          {text}
        </Tag>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => formatBTCPrice(price),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => `${amount} ${tick}`,
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: number) => new Date(timestamp).toLocaleString(),
    },
    {
      title: 'Transaction',
      key: 'action',
      render: (_: any, record: ActionItem) => (
        record.event === 'Sold' ? (
          <Link to={`https://explorer.unisat.io/fractal-mainnet/tx/${record.txid}`} target="_blank" rel="noopener noreferrer">
            View
          </Link>
        ) : '-'
      ),
    },
  ];

  if (!isConnected) {
    return <Title level={3} style={{ color: '#FFFFFF' }}>Please connect your wallet to view token details</Title>;
  }

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <Title level={3} style={{ color: '#FFFFFF' }}>{error}</Title>;
  }

  const isFractionalized = FRACTIONALIZED_TOKENS.includes(tick || '');

  return (
    <Content style={{ padding: '24px', background: '#121212', minHeight: '100vh', color: '#FFFFFF' }}>
      <GlowingCard>
        <Row gutter={[24, 24]} align="middle">
          <Col xs={24} md={8}>
            <ImageContainer>
              <Image
                src={tickerImageMap[tick || ''] || '/images/default-nft.jpg'}
                fallback="/images/default-nft.jpg"
                style={{ 
                  width: getImageSize(), 
                  height: getImageSize(), 
                  objectFit: 'cover', 
                  borderRadius: '10px' 
                }}
              />
            </ImageContainer>
          </Col>
          <Col xs={24} md={16}>
            <ShimmeringText>{tick?.toUpperCase()}</ShimmeringText>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <PulsingStatistic
                  title={<Text strong style={{ color: '#FFFFFF' }}>Current Price</Text>}
                  value={formatSatsToFB(tokenData?.curPrice || 0)}
                  suffix="FB"
                  valueStyle={{ color: '#FF6600', fontSize: '1.5rem' }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<Text strong style={{ color: '#FFFFFF' }}>24h Change</Text>}
                  value={tokenData?.changePrice || 0}
                  precision={2}
                  valueStyle={{
                    color: tokenData?.changePrice && tokenData.changePrice > 0 ? '#52c41a' : '#ff4d4f',
                    fontSize: '1.5rem'
                  }}
                  suffix="%"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<Text strong style={{ color: '#FFFFFF' }}>Total Supply</Text>}
                  value={formatLargeNumber(tokenData?.totalMinted)}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<Text strong style={{ color: '#FFFFFF' }}>Holders</Text>}
                  value={formatLargeNumber(tokenData?.holders)}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={<Text strong style={{ color: '#FFFFFF' }}>24h Volume</Text>}
                  value={formatBTCVolume(tokenData?.fbVolume)}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {isFractionalized && magicEdenStats && (
          <>
            <Divider style={{ borderColor: 'rgba(255,255,255,0.1)' }} />
            <Title level={4} style={{ color: '#FFFFFF', margin: '0 0 16px 0' }}>NFT Collection: {magicEdenStats.symbol}</Title>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={4}>
                <Statistic
                  title="Floor Price"
                  value={Number(formatBTCPrice(magicEdenStats.floorPrice)).toFixed(4)}
                  suffix="BTC"
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <Statistic
                  title="Total Volume"
                  value={formatBTCVolume(magicEdenStats.totalVolume)}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <Statistic
                  title="Collection Supply"
                  value={magicEdenStats.supply}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <Statistic
                  title="Unique Owners"
                  value={magicEdenStats.owners}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Col>
              <Col xs={24} sm={24} md={16} lg={8}>
                <Tooltip title="View on Ordiscan">
                  <div onClick={() => window.open(`https://ordiscan.com/address/${assetDetails[tick || '']?.multisigAddress}`, '_blank')} style={{ cursor: 'pointer' }}>
                    <Statistic 
                      title="Multisig Vault Address" 
                      value={formatAddress(assetDetails[tick || '']?.multisigAddress)}
                      valueStyle={{ color: '#4a90e2' }}
                      suffix={<InfoCircleOutlined />}
                    />
                  </div>
                </Tooltip>
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: '24px' }}>
              <Col>
                <StyledButton 
                  icon={<ShoppingOutlined />}
                  onClick={() => window.open(`https://magiceden.io/ordinals/marketplace/${magicEdenStats.symbol}`, '_blank')}
                  size="large"
                >
                  View this collection on Magic Eden
                </StyledButton>
              </Col>
            </Row>
          </>
        )}
      </GlowingCard>

      <Tabs
        defaultActiveKey="1"
        style={{ marginTop: 24 }}
        className="custom-tabs"
      >
        <TabPane tab={<span><ShoppingOutlined /> Market</span>} key="1">
          <Listed tick={tick || ''} />
        </TabPane>
        <TabPane tab={<span><WalletOutlined /> My Assets</span>} key="2">
          <Assets tick={tick || ''} />
        </TabPane>
        <TabPane tab={<span><HistoryOutlined /> History</span>} key="3">
          <Table
            columns={actionColumns}
            dataSource={actions}
            rowKey={(record) => record.txid || `${record.timestamp}-${record.event}-${record.amount}`}
          />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default TokenDetailPage;