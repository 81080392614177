import axios, { AxiosInstance } from 'axios';
import { config } from './config';

const api: AxiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleResponse = (response: any, isMagicEden: boolean = false) => {
  if (isMagicEden) {
    
    return response.data;
  }
  
  
  if (response.data.code !== 0) {
    throw new Error(response.data.msg || 'Unknown error occurred');
  }
  return response.data.data;
};

export const get = async (url: string, params?: any, useFractal: boolean = false) => {
  try {
    const isMagicEden = url.startsWith('/magiceden');
    console.log(`GET request to ${url}`, params);
    const response = await api.get(url, { 
      params,
      headers: useFractal ? { 'X-Use-Fractal': 'true' } : {}
    });
    console.log(`GET response from ${url}:`, response.data);
    return handleResponse(response, isMagicEden);
  } catch (error: any) {
    console.error('GET request error:', error.response?.data || error.message);
    throw error;
  }
};

export const post = async (url: string, data?: any, useFractal: boolean = false) => {
  try {
    console.log(`POST request to ${url}`, data);
    const response = await api.post(url, data, {
      headers: useFractal ? { 'X-Use-Fractal': 'true' } : {}
    });
    return handleResponse(response);
  } catch (error: any) {
    console.error('POST request error:', error.response?.data || error.message);
    throw new Error(error.response?.data?.msg || error.message);
  }
};