import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { App as AntApp } from 'antd';
import { HomePage } from './page/HomePage';
import { FraktionApp } from './page/FraktionApp';

function App() {
  return (
    <AntApp>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/app/*" element={<FraktionApp />} />
        </Routes>
      </Router>
    </AntApp>
  );
}

export default App;