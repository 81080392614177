import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import './style/responsive.css';
import App from './App';
import { ConfigProvider } from "antd";
import UnisatProvider from "./provider/UniSatProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#FF6600",
          colorBgBase: "#121212",
          colorTextBase: "#FFFFFF",
        },
        components: {
          Button: {
            colorPrimaryHover: "#FF8533",
          },
          Input: {
            colorBgContainer: "#252525",
            colorBorder: "#808080",
          },
          Select: {
            colorBgContainer: "#252525",
            colorBorder: "#808080",
          },
          Table: {
            colorBgContainer: "#252525",
            colorBorderSecondary: "#808080",
          },
          Layout: {
            headerHeight: 64,
            headerPadding: 24,
          },
          Card: {
            paddingLG: 24,
          },
        },
      }}
    >
      <UnisatProvider>
        <App />
      </UnisatProvider>
    </ConfigProvider>
  </React.StrictMode>
);