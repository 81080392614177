import React from 'react';
import { Layout, Image } from 'antd';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import { WalletConnection } from '../components/WalletConnection';
import { TickerTable } from './TickerTable';
import { TokenDetailPage } from './TokenDetailPage';
import { UnderConstruction } from './UnderConstruction';
import InfoBar from '../components/InfoBar';
import './FraktionApp.css';

const { Header, Content, Footer } = Layout;

export function FraktionApp() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Layout className="app-layout">
      <div className="background-effect">
        <div className="light light-1"></div>
        <div className="light light-2"></div>
        <div className="light light-3"></div>
      </div>
      <Header className="app-header">
        <div className="header-content">
          <div className="logo-container" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <Image
              src="/images/logo_v2_20240926.png"
              alt="Logo"
              preview={false}
              height={40}
            />
          </div>
          <nav className="menu-container">
            <Link to="/app" className="menu-item">FraKtion NFT's</Link>
            <Link to="/app/Dashboard" className="menu-item">Dashboard</Link>
            <Link to="/app/Soon" className="menu-item">Soon</Link>
            <a 
              href="https://fraktion-1.gitbook.io/fraktion.pro-documentation/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="menu-item"
            >
              Documentation
            </a>
          </nav>
          <div className="right-section">
            <div className="social-links">
              <a href="https://x.com/FraKtionPro" target="_blank" rel="noopener noreferrer" className="social-link">
                <Image src="/images/twitter-icon.png" alt="Twitter" preview={false} width={38} />
              </a>
              <a href="https://t.me/FraKtionPro" target="_blank" rel="noopener noreferrer" className="social-link">
                <Image src="/images/telegram-icon.png" alt="Telegram" preview={false} width={38} />
              </a>
            </div>
            <WalletConnection />
          </div>
        </div>
      </Header>
      <Content className="app-content">
        <Routes>
          <Route path="/" element={<TickerTable />} />
          <Route path="token/:tick" element={<TokenDetailPage />} />
          <Route path="Dashboard" element={<UnderConstruction pageName="Dashboard" />} />
          <Route path="Soon" element={<UnderConstruction pageName="Soon" />} />
          <Route path="*" element={<div>Page not found</div>} />
        </Routes>
      </Content>
      <Footer style={{ padding: 0, height: 'auto' }}>
        <InfoBar />
      </Footer>
    </Layout>
  );
}

export default FraktionApp;