import { post, get } from "./httpUtils";
import { config } from './config';
import axios from 'axios';

export enum InscriptionType {
    brc20 = "brc20",
    domain = "domain",
    collection = "collection",
    arc20 = "arc20",
}

export type ListReqFilter = {
    address?: string;
    nftConfirm?: boolean;
    minPrice?: number;
    maxPrice?: number;
    isEnd?: boolean;
    nftType?: InscriptionType;
    tick?: string;
}

export type ListReq = {
    filter: ListReqFilter;
    sort: {
        unitPrice?: -1 | 1;
        onSaleTime?: -1 | 1;
    };
    start: number;
    limit: number;
};

export type ListItem = {
    auctionId: string;
    inscriptionId: string;
    inscriptionName: string;
    inscriptionNumber: number;
    marketType: 'fixedPrice';
    nftType: InscriptionType;
    initPrice: number;
    curPrice: number;
    minBidPrice: number;
    endTime: number;
    address: string;
    onSaleTime: number;
    price: number;
};

export type BRC20ListItem = ListItem & {
    tick: string;
    limit: number;
    amount: number;
    unitPrice: number;
};

export type BRC20ListRes = {
    list: BRC20ListItem[];
    total: number;
};

export type CreatePutOnReq = {
    inscriptionId: string;
    initPrice: string;
    pubkey: string;
    marketType: 'fixedPrice';
    auctionTime?: number;
    maxPrice?: number;
    unitPrice?: string;
    fbAddress?: string;
    nftType: InscriptionType;
};

export type CreatePutOnRes = {
    auctionId: string;
    psbt: string;
    signIndexes: number[];
};

export type ConfirmPutOnReq = {
    auctionId: string;
    psbt: string;
    fromBase64?: boolean;
};

export type ConfirmPutOnRes = {};

export type CreateBidPrepareReq = {
    auctionId: string;
    bidPrice: number;
    address: string;
    pubkey: string;
};

export type CreateBidPrepareRes = {
    serverFee: number;
    serverReal: number;
    serverFeeRate: number;
    txSize: number;
    nftValue: number;
    feeRate: number;
    discounts: {
        name: string;
        percent: number;
    }[];
    inscriptionCount: number;
    availableBalance: number;
};

export type CreateBidReq = {
    auctionId: string;
    bidPrice: number;
    address: string;
    pubkey: string;
    feeRate?: number;
    nftAddress?: string;
    sign?: string;
};

export type CreateBidRes = {
    bidId: string;
    psbtBid: string;
    psbtBid2: string;
    psbtSettle: string;
    networkFee: number;
    feeRate: number;
    serverFee: number;
    nftValue: number;
    bidSignIndexes: number[];
};

export type ConfirmBidReq = {
    auctionId: string;
    bidId: string;
    psbtBid: string;
    psbtBid2: string;
    psbtSettle: string;
    fromBase64?: boolean;
};

export type ConfirmBidRes = {
    txid: string;
};

export type CreatePutOffReq = {
    auctionId: string;
    fbPubkey?: string;
    nftAddress?: string;
};

export type CreatePutOffRes = {
    psbt: string;
    fbSignIndexes: number[];
    nftSignIndexes: number[];
};

export type ConfirmPutOffReq = {
    auctionId: string;
    psbt: string;
    fromBase64?: boolean;
};

export type ConfirmPutOffRes = {
    txid: string;
};

export type Brc20Info = {
    imageUrl: string;
    curPrice: number;
    changePrice: number;
    fbVolume: number;
    cap: string;
    totalMinted: string;
    holders: number;
    creator: string;
    topHolders: { address: string; balance: string }[];
};

export type PriceHistoryItem = {
    timestamp: number;
    price: number;
};

export type ActionItem = {
    auctionId: string;
    inscriptionId: string;
    inscriptionNumber: number;
    event: 'Listed' | 'Sold' | 'Delisted';
    price: number;
    from: string;
    to: string;
    timestamp: number;
    txid: string;
    amount: number;
};

export type UpdatePriceReq = {
    auctionId: string;
    newPrice: number;
    amount: number;
};

export type NetworkFeesRes = {
    recommendedFee: number;
    recommendedFeeRate: number;
    estimatedTxSize: number;
};

export const marketApi = {
    listBrc20(req: ListReq): Promise<BRC20ListRes> {
        return post('/v3/market/brc20/auction/list', req, true);
    },

    createPutOn(req: CreatePutOnReq): Promise<CreatePutOnRes> {
        return post('/v3/market/brc20/auction/create_put_on', req, true);
    },

    confirmPutOn(req: ConfirmPutOnReq): Promise<ConfirmPutOnRes> {
        return post('/v3/market/brc20/auction/confirm_put_on', req, true);
    },

    createBidPrepare(req: CreateBidPrepareReq): Promise<CreateBidPrepareRes> {
        return post('/v3/market/brc20/auction/create_bid_prepare', req, true);
    },

    createBid(req: CreateBidReq): Promise<CreateBidRes> {
        return post('/v3/market/brc20/auction/create_bid', req, true);
    },

    confirmBid(req: ConfirmBidReq): Promise<ConfirmBidRes> {
        return post('/v3/market/brc20/auction/confirm_bid', req, true);
    },

    createPutOff(req: CreatePutOffReq): Promise<CreatePutOffRes> {
        return post('/v3/market/brc20/auction/create_put_off', req, true);
    },

    confirmPutOff(req: ConfirmPutOffReq): Promise<ConfirmPutOffRes> {
        return post('/v3/market/brc20/auction/confirm_put_off', req, true);
    },

    getFBPrice(): Promise<{ FBPrice: number }> {
        return post('/v3/market/brc20/auction/brc20_types', {
            timeType: 'day1',
            ticks: [config.DEFAULT_TOKENS[0]],
        }, true);
    },

    getBrc20Info(tick: string): Promise<Brc20Info> {
        return post('/v3/market/brc20/auction/brc20_types_specified', {
            timeType: 'day1',
            tick: tick,
        }, true);
    },

    getBrc20PriceHistory(tick: string): Promise<PriceHistoryItem[]> {
        return post('/v3/market/brc20/auction/brc20_kline', {
            tick: tick,
            timeStart: Date.now() - 30 * 24 * 60 * 60 * 1000,
            timeEnd: Date.now(),
            timeStep: 24 * 60 * 60 * 1000,
        }, true);
    },

    getActions(params: { filter: any, start: number, limit: number }): Promise<{ list: ActionItem[], total: number }> {
        return post('/v3/market/brc20/auction/actions', params, true);
    },

    async updatePrice(req: UpdatePriceReq): Promise<{ psbt: string; signIndexes: number[] }> {
        try {
            console.log('Update price request:', req);
            const response = await post('/v3/market/brc20/auction/create_modify_price', {
                auctionId: req.auctionId,
                initPrice: req.newPrice.toString(),
                unitPrice: (req.newPrice / req.amount).toFixed(8)
            }, true);
            
            console.log('Update price response:', response);

            if (response && response.psbt && response.signIndexes) {
                return {
                    psbt: response.psbt,
                    signIndexes: response.signIndexes
                };
            } else {
                throw new Error('Unexpected response structure from create_modify_price');
            }
        } catch (error) {
            console.error('Error in updatePrice:', error);
            if (error instanceof Error) {
                throw new Error(`Price setting error: ${error.message}`);
            } else {
                throw new Error('Unknown price setting error');
            }
        }
    },

    confirmModifyPrice(req: { auctionId: string; psbt: string }): Promise<void> {
        return post('/v3/market/brc20/auction/confirm_modify_price', {
            ...req,
            fromBase64: false
        }, true);
    },

    getBTCPrice: async (): Promise<number> => {
        const response = await get('/api/btc-price');
        return response.bitcoin.usd;
      },
    
      getNetworkFees: async (): Promise<{ fastestFee: number, halfHourFee: number, hourFee: number }> => {
        try {
          const response = await get('/api/mempool-fees');
          return response;
        } catch (error) {
          console.error('Failed to fetch network fees, using default values:', error);
          return {
            fastestFee: 5,
            halfHourFee: 3,
            hourFee: 1
          };
        }
      },


    getBlockchainInfo(): Promise<any> {
        return get('/v1/indexer/blockchain/info', undefined, true);
    },

    getAddressBalance(address: string): Promise<any> {
        return get(`/v1/indexer/address/${address}/balance`, undefined, true);
    },

    getBrc20List(): Promise<any> {
        return get('/v1/indexer/brc20/list', undefined, true);
    },

    getBrc20TokenInfo(ticker: string): Promise<any> {
        return get(`/v1/indexer/brc20/${ticker}/info`, undefined, true);
    },

    getBrc20AddressSummary(address: string): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/summary`, undefined, true);
    },

    getInscriptionInfo(inscriptionId: string): Promise<any> {
        return get(`/v1/indexer/inscription/info/${inscriptionId}`, undefined, true);
    },

    getBrc20TransferableInscriptions(address: string, ticker: string): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/${ticker}/transferable-inscriptions`, undefined, true);
    },


    getCryptoPrices: async (): Promise<{ bitcoin: { usd: number }, 'fractal-bitcoin': { usd: number } }> => {
        const response = await get('/api/crypto-prices');
        return response;
      },
      
      getBitcoinPuppetsStats: async (): Promise<any> => {
        try {
          const response = await get('/magiceden/stat?collectionSymbol=bitcoin-puppets');
          console.log('Bitcoin Puppets Stats:', response);
          return response;
        } catch (error) {
          console.error('Error fetching Bitcoin Puppets stats:', error);
          throw error;
        }
      },

      getCollectionStats: async (symbol: string): Promise<any> => {
        try {
          const response = await get(`/magiceden/stat?collectionSymbol=${symbol}`);
          console.log('Collection Stats:', response);
          return response;
        } catch (error) {
          console.error('Error fetching collection stats:', error);
          return null;
        }
      },

      getBitcoinPrice: async (): Promise<number> => {
        try {
            const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
            return response.data.bitcoin.usd;
        } catch (error) {
            console.error('Erreur lors de la récupération du prix du Bitcoin:', error);
            throw error;
        }
    }

      
          };