import React from 'react';
import { useUnisat } from '../provider/UniSatProvider';
import { Button, Modal, message } from 'antd';
import { WalletOutlined, LogoutOutlined } from '@ant-design/icons';

export const WalletConnection = () => {
  const { isConnected, address, connect, disconnect, signedMessage } = useUnisat();

  const handleConnect = async () => {
    try {
      await connect();
      message.success('Wallet connected successfully!');
    } catch (error) {
      message.error('Failed to connect wallet');
    }
  };

  const handleDisconnect = () => {
    disconnect();
    message.info('Wallet disconnected');
  };

  const showAddressModal = () => {
    Modal.info({
      title: 'Connected Wallet',
      content: (
        <div>
          <p>Address: {address}</p>
          <p>Signed Message: {signedMessage ? 'Verified' : 'Not verified'}</p>
        </div>
      ),
    });
  };

  return (
    <div>
      {isConnected ? (
        <>
          <Button icon={<WalletOutlined />} onClick={showAddressModal} style={{ marginRight: '10px' }}>
            {`${address.slice(0, 6)}...${address.slice(-4)}`}
          </Button>
          <Button icon={<LogoutOutlined />} onClick={handleDisconnect}>
            Disconnect
          </Button>
        </>
      ) : (
        <Button type="primary" icon={<WalletOutlined />} onClick={handleConnect}>
          Connect Wallet
        </Button>
      )}
    </div>
  );
};

export default WalletConnection;