import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, message, Typography } from 'antd';
import { marketApi, BRC20ListItem } from '../utils/marketApi';
import { useUnisat } from '../provider/UniSatProvider';
import { formatPrice, formatUnitPrice } from '../utils/formatters';

const { Text } = Typography;

interface UpdatePriceModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: BRC20ListItem | null;
    onSuccess: () => void;
}

const UpdatePriceModal: React.FC<UpdatePriceModalProps> = ({ isOpen, onClose, item, onSuccess }) => {
    const [newPrice, setNewPrice] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { signPsbt } = useUnisat();

    useEffect(() => {
        if (item) {
            setNewPrice(item.unitPrice.toString());
        }
    }, [item]);

    const handleUpdatePrice = async () => {
        if (!item) return;
        if (!newPrice || isNaN(parseFloat(newPrice))) {
            setError('Please enter a valid price');
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            console.log('Updating price for item:', item);
            const createResponse = await marketApi.updatePrice({
                auctionId: item.auctionId,
                newPrice: Math.floor(parseFloat(newPrice) * item.amount),
                amount: item.amount
            });
            console.log('Create modify price response:', createResponse);

            if (createResponse && createResponse.psbt) {
                console.log('Signing PSBT...');
                const signedPsbt = await signPsbt(createResponse.psbt);
                console.log('Signed PSBT:', signedPsbt);
                
                console.log('Confirming modify price...');
                await marketApi.confirmModifyPrice({
                    auctionId: item.auctionId,
                    psbt: signedPsbt
                });
                console.log('Price modification confirmed');
                
                message.success('Price updated successfully');
                onSuccess();
                onClose();
            } else {
                throw new Error('Invalid response from create_modify_price');
            }
        } catch (error) {
            console.error('Error updating price:', error);
            if (error instanceof Error) {
                setError(`Failed to update price: ${error.message}`);
            } else {
                setError('An unknown error occurred while updating the price');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const totalValueFB = item ? (parseFloat(newPrice) * item.amount) / 100000000 : 0;

    return (
        <Modal
            title={<span style={{ color: '#FFFFFF' }}>Update Price</span>}
            open={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={isLoading} onClick={handleUpdatePrice}>
                    Update Price
                </Button>,
            ]}
            className="custom-modal"
        >
            <Input
                placeholder="Enter new price in sats"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                type="number"
                addonAfter="sats"
            />
            <div style={{ marginTop: '10px', color: '#FFFFFF' }}>
                <p>Amount: {item?.amount} {item?.tick}</p>
                <p>New Unit Price: {formatUnitPrice(parseFloat(newPrice))} sats</p>
                <p>Total Value: {formatPrice(totalValueFB)} FB</p>
            </div>
            {error && <Text type="danger" style={{ display: 'block', marginTop: '10px' }}>{error}</Text>}
        </Modal>
    );
};

export default UpdatePriceModal;