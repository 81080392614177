import { get } from "./httpUtils";

export type AddressBrc20Balance = {
    ticker: string;
    overallBalance: string;
    transferableBalance: string;
    availableBalance: string;
}

export type Brc20Data = {
    amt: string;
    decimal: string;
    lim: string;
    max: string;
    minted: string;
    op: string;
    tick: string;
    to?: string;
}

export type Brc20InscriptionsItem = {
    data: Brc20Data;
    inscriptionId: string;
    inscriptionNumber: number;
    confirmations: number;
    isPutOn?: boolean;
}

export type Brc20AddressTransferable = {
    start: number;
    total: number;
    detail: Brc20InscriptionsItem[];
}

export type Brc20TickerInfo = {
    ticker: string;
    creator: string;
    totalMinted: string;
    confirmedMinted: string;
    confirmedMinted1h: string;
    confirmedMinted24h: string;
    minted: string;
    txid: string;
    inscriptionId: string;
    max: string;
    limit: string;
}

export type Brc20Holder = {
    address: string;
    availableBalance: string;
    overallBalance: string;
    transferableBalance: string;
}

export const brc20Api = {
    getBestHeight(): Promise<{ height: number }> {
        return get('/v1/indexer/brc20/bestheight');
    },

    getList(params?: { start?: number; limit?: number }): Promise<{ total: number; start: number; detail: Brc20TickerInfo[] }> {
        return get('/v1/indexer/brc20/list', params);
    },

    getStatus(): Promise<any> {
        return get('/v1/indexer/brc20/status');
    },

    getTickerInfo(ticker: string): Promise<Brc20TickerInfo> {
        return get(`/v1/indexer/brc20/${ticker}/info`);
    },

    getHolders(ticker: string, params?: { start?: number; limit?: number }): Promise<{ total: number; start: number; detail: Brc20Holder[] }> {
        return get(`/v1/indexer/brc20/${ticker}/holders`, params);
    },

    getTickerHistory(ticker: string, params?: { start?: number; limit?: number }): Promise<any> {
        return get(`/v1/indexer/brc20/${ticker}/history`, params);
    },

    getTickerTxHistory(ticker: string, txid: string): Promise<any> {
        return get(`/v1/indexer/brc20/${ticker}/tx/${txid}/history`);
    },

    getHistoryByHeight(height: number): Promise<any> {
        return get(`/v1/indexer/brc20/history-by-height/${height}`);
    },

    getAddressBrc20Summary(address: string, params?: { start?: number; limit?: number }): Promise<{
        total: number;
        start: number;
        height: number;
        detail: AddressBrc20Balance[];
    }> {
        return get(`/v1/indexer/address/${address}/brc20/summary`, params);
    },

    getAddressBrc20SummaryByHeight(address: string, height: number, params?: { start?: number; limit?: number }): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/summary-by-height/${height}`, params);
    },

    getAddressBrc20TickerInfo(address: string, ticker: string): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/${ticker}/info`);
    },

    getAddressBrc20History(address: string, params?: { start?: number; limit?: number }): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/history`, params);
    },

    getAddressBrc20TickerHistory(address: string, ticker: string, params?: { start?: number; limit?: number }): Promise<any> {
        return get(`/v1/indexer/address/${address}/brc20/${ticker}/history`, params);
    },

    getAddressTransferable({ address, tick, start = 0, limit = 512 }: {
        address: string;
        tick: string;
        start?: number;
        limit?: number;
    }): Promise<Brc20AddressTransferable> {
        return get(`/v1/indexer/address/${address}/brc20/${tick}/transferable-inscriptions`, {
            limit,
            start,
        });
    },
};

export default brc20Api;