import React, { useEffect, useState } from "react";
import { BRC20ListItem, CreateBidPrepareRes, marketApi } from "../utils/marketApi";
import { Col, Flex, Modal, Row, Skeleton, Statistic, Typography, Tooltip, Slider, InputNumber, Radio } from "antd";
import { Brc20Item } from "./Brc20Item";
import { useUnisat } from "../provider/UniSatProvider";
import { handleError } from "../utils/utils";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatUnitPrice, formatSatsToFB, calculateTotalFB } from "../utils/formatters";
import axios from 'axios';

const { Text } = Typography;

interface FeeOption {
  label: string;
  value: number;
}

const DEFAULT_FEE_OPTIONS: FeeOption[] = [
  { label: 'Slow', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Fast', value: 3 }
];

export function BuyConfirmAlert({item, close, onComplete}: {
    item: BRC20ListItem,
    close: () => void,
    onComplete?: () => void
}) {
    const {address, pubkey, signPsbt} = useUnisat();
    const [isLoading, setIsLoading] = useState(false);
    const [bidPrepare, setBidPrepare] = useState<CreateBidPrepareRes | undefined>();
    const [feeRate, setFeeRate] = useState(2);
    const [feeOptions, setFeeOptions] = useState<FeeOption[]>(DEFAULT_FEE_OPTIONS);
    const [selectedFeeOption, setSelectedFeeOption] = useState<string>('medium');

    useEffect(() => {
        if (!address || !pubkey || !item) return;
        
        const fetchData = async () => {
            try {
                const [bidPrepareRes, feesRes] = await Promise.all([
                    marketApi.createBidPrepare({
                        auctionId: item.auctionId,
                        bidPrice: item.price,
                        address,
                        pubkey
                    }),
                    axios.get('/api/mempool-fees')
                ]);

                setBidPrepare(bidPrepareRes);

                if (feesRes.data && feesRes.data.economyFee) {
                    const { economyFee, hourFee, fastestFee } = feesRes.data;
                    setFeeOptions([
                        { label: 'Slow', value: economyFee },
                        { label: 'Medium', value: hourFee },
                        { label: 'Fast', value: fastestFee }
                    ]);
                    setFeeRate(hourFee); // Set default to medium
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // If there's an error, we'll keep the default fee options
            }
        };

        fetchData();
    }, [address, item, pubkey]);

    const handleFeeOptionChange = (e: any) => {
        const option = feeOptions.find(opt => opt.label.toLowerCase() === e.target.value);
        if (option) {
            setFeeRate(option.value);
            setSelectedFeeOption(e.target.value);
        }
    };

    const handleCustomFeeChange = (value: number | null) => {
        if (value !== null) {
            setFeeRate(value);
            setSelectedFeeOption('custom');
        }
    };

    async function buy() {
        try {
            setIsLoading(true);
            const {bidId, psbtBid} = await marketApi.createBid({
                auctionId: item.auctionId,
                bidPrice: item.price,
                address,
                pubkey,
                feeRate: feeRate,
            });
            const signedPsbt = await signPsbt(psbtBid);
            const result = await marketApi.confirmBid({
                auctionId: item.auctionId,
                bidId,
                psbtBid: signedPsbt,
                psbtBid2: '',
                psbtSettle: '',
            });
            Modal.success({
                title: 'Purchase Successful',
                content: (
                    <div>
                        <p>Amount: {item.amount} {item.tick}</p>
                        <p>Price: {formatSatsToFB(item.price)} FB</p>
                        <p>Network Fee: {formatSatsToFB(networkFee)} FB</p>
                        <p>Total Paid: {formatSatsToFB(item.price + networkFee)} FB</p>
                        <p>
                            <a href={`https://explorer.unisat.io/fractal-mainnet/tx/${result.txid}`} target="_blank" rel="noopener noreferrer">
                                View transaction on Explorer
                            </a>
                        </p>
                    </div>
                ),
                onOk: () => {
                    onComplete && onComplete();
                }
            });
        } catch (e) {
            handleError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const isFeeFree = item.price < 5000000; // 0.05 FB in satoshis
    const unisatFeeRate = 0.005; // 0.5%
    const unisatFee = Math.floor(item.price * unisatFeeRate);
    const actualUnisatFee = isFeeFree ? 0 : unisatFee;
    const networkFee = bidPrepare ? bidPrepare.txSize * feeRate : 0;

    return (
        <Modal
            open={!!item}
            onCancel={close}
            title={<span style={{ color: '#FFFFFF' }}>Confirm Purchase</span>}
            confirmLoading={isLoading}
            onOk={buy}
            className="custom-modal"
        >
            {item && (
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <Flex justify="center">
                            <Brc20Item tick={item.tick} amount={item.amount}/>
                        </Flex>
                    </Col>

                    <KeyValue 
                        label="Unit Price:" 
                        value={formatUnitPrice(item.unitPrice)} 
                        suffix={`sats/${item.tick}`}
                    />
                    <KeyValue 
                        label="Total Price:" 
                        value={formatSatsToFB(item.price, 4)} 
                        suffix="FB"
                    />
                    {!bidPrepare ? (
                        <Skeleton active/>
                    ) : (
                        <>
                            <Col span={24}>
                                <Text>Transaction Fee Options</Text>
                                <Radio.Group onChange={handleFeeOptionChange} value={selectedFeeOption} style={{ marginTop: 8 }}>
                                    {feeOptions.map((option) => (
                                        <Radio.Button key={option.label.toLowerCase()} value={option.label.toLowerCase()}>
                                            {option.label} ({option.value} sat/vB)
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Col>
                            <Col span={24} style={{ marginTop: 16 }}>
                                <Text>Custom Fee Rate (sat/vB)</Text>
                                <Flex align="center">
                                    <Slider
                                        min={0}
                                        max={10000}
                                        onChange={handleCustomFeeChange}
                                        value={feeRate}
                                        style={{ flex: 1, marginRight: 16 }}
                                    />
                                    <InputNumber
                                        min={0}
                                        max={10000}
                                        value={feeRate}
                                        onChange={handleCustomFeeChange}
                                    />
                                </Flex>
                            </Col>
                            <KeyValue 
                                label="Network fee" 
                                value={formatSatsToFB(networkFee, 4)}
                                suffix="FB"
                            />
                            <Col span={24}>
                                <Text>
                                    Estimated transaction size: {bidPrepare.txSize} vB
                                </Text>
                            </Col>
                            <Col span={24}>
                                <Text>
                                    Fee calculation: {feeRate} sat/vB × {bidPrepare.txSize} vB = {formatSatsToFB(networkFee, 4)} FB
                                </Text>
                            </Col>
                            <KeyValue 
                                label={
                                    <span>
                                        UniSat fee (0.5%)
                                        <Tooltip title="Items priced below 0.05 FB are exempt from UniSat service fees">
                                            <ExclamationCircleOutlined style={{ marginLeft: 5 }} />
                                        </Tooltip>
                                    </span>
                                }
                                value={formatSatsToFB(unisatFee, 4)} 
                                suffix="FB"
                                isStrikeThrough={isFeeFree}
                            />
                            {isFeeFree && (
                                <KeyValue 
                                    label="Actual UniSat fee"
                                    value={formatSatsToFB(0, 4)} 
                                    suffix="FB"
                                />
                            )}
                            <KeyValue 
                                label="Total" 
                                value={calculateTotalFB([item.price, actualUnisatFee, networkFee], 4)}
                                suffix="FB" 
                                valueColor="var(--main-color)"
                                fontSize={22}
                            />
                        </>
                    )}
                </Row>
            )}
        </Modal>
    );
}

function KeyValue({
    label,
    value,
    suffix,
    fontSize = 14,
    valueColor,
    isStrikeThrough = false
}: {
    label: React.ReactNode,
    value: string | number,
    suffix?: string,
    fontSize?: number,
    valueColor?: string,
    isStrikeThrough?: boolean
}) {
    return (
        <>
            <Col span={8} style={{textAlign: 'right'}}>
                {label}
            </Col>
            <Col span={16}>
                <Statistic
                    valueStyle={{
                        fontSize, 
                        color: valueColor,
                        textDecoration: isStrikeThrough ? 'line-through' : 'none'
                    }}
                    value={value}
                    suffix={suffix}
                />
            </Col>
        </>
    );
}

export default BuyConfirmAlert;