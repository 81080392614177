import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { handleError, sleep } from "../utils/utils";
import { unisatUtils } from "../utils/unisatUtils";
import { marketApi, CreatePutOnReq, CreateBidReq, ConfirmBidReq, CreatePutOffReq } from "../utils/marketApi";
import { message } from "antd";
import { config } from "../utils/config";

interface UnisatContextType {
  isInstalled: boolean;
  isConnected: boolean;
  address: string;
  pubkey: string;
  connect: () => Promise<void>;
  disconnect: () => void;
  signMessage: (msg: string, type?: string) => Promise<string>;
  signPsbt: (psbt: string) => Promise<string>;
  getPublicKey: () => Promise<string>;
  getBalance: () => Promise<{ confirmed: number; unconfirmed: number; total: number }>;
  inscribeTransfer: (tick: string, amount?: number | string) => Promise<{
    amount: string;
    inscriptionId: string;
    inscriptionNumber: number;
    ticker: string;
  }>;
  signedMessage: string | null;
  
  // Market functions
  createListing: (req: CreatePutOnReq) => Promise<void>;
  createBid: (req: CreateBidReq) => Promise<void>;
  confirmBid: (req: ConfirmBidReq) => Promise<void>;
  cancelListing: (req: CreatePutOffReq) => Promise<void>;
  getListings: (filter: any, sort: any, start: number, limit: number) => Promise<any>;
  getMarketInfo: (tick: string) => Promise<any>;
}

const UnisatContext = createContext<UnisatContextType | undefined>(undefined);

export function useUnisat() {
  const context = useContext(UnisatContext);
  if (!context) {
    throw new Error('useUnisat must be used within a UnisatProvider');
  }
  return context;
}

export function UnisatProvider({ children }: { children: ReactNode }) {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [pubkey, setPubkey] = useState('');
  const [signedMessage, setSignedMessage] = useState<string | null>(null);

  useEffect(() => {
    const checkUnisat = async () => {
      for (let i = 0; i < 10; i++) {
        if (window.unisat) {
          setIsInstalled(true);
          break;
        }
        await sleep(500);
      }
    };

    checkUnisat();
  }, []);

  useEffect(() => {
    if (isInstalled) {
      unisatUtils.getAccounts().then((accounts) => {
        if (accounts.length > 0) {
          setIsConnected(true);
          setAddress(accounts[0]);
          unisatUtils.getPublicKey().then(setPubkey);
        }
      }).catch(handleError);
    }
  }, [isInstalled]);

  const switchToFractalNetwork = async () => {
    try {
      await window.unisat.switchChain(config.NETWORK);
      message.success('Successfully switched to Fractal network');
    } catch (error) {
      console.error('Failed to switch network:', error);
      message.error('Failed to switch to Fractal network. Please switch manually.');
    }
  };

  const connect = useCallback(async () => {
    try {
      const accounts = await unisatUtils.requestAccounts();
      if (accounts.length > 0) {
        const currentNetwork = await window.unisat.getNetwork();
        if (currentNetwork !== config.NETWORK) {
          await switchToFractalNetwork();
        }
        
        const message = `Welcome to our Fractal FraKtion marketplace! Please sign this message to verify your ownership of the address ${accounts[0]}. Timestamp: ${Date.now()}`;
        const signature = await unisatUtils.signMessage(message);
        setSignedMessage(signature);
        setIsConnected(true);
        setAddress(accounts[0]);
        setPubkey(await unisatUtils.getPublicKey());
      }
    } catch (error) {
      handleError(error);
    }
  }, []);

  const disconnect = useCallback(() => {
    setIsConnected(false);
    setAddress('');
    setPubkey('');
    setSignedMessage(null);
  }, []);

  const createListing = useCallback(async (req: CreatePutOnReq) => {
    try {
      const res = await marketApi.createPutOn(req);
      const signedPsbt = await unisatUtils.signPsbt(res.psbt);
      await marketApi.confirmPutOn({ auctionId: res.auctionId, psbt: signedPsbt });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const createBid = useCallback(async (req: CreateBidReq) => {
    try {
      const res = await marketApi.createBid(req);
      const signedPsbt = await unisatUtils.signPsbt(res.psbtBid);
      await marketApi.confirmBid({
        auctionId: req.auctionId,
        bidId: res.bidId,
        psbtBid: signedPsbt,
        psbtBid2: res.psbtBid2,
        psbtSettle: res.psbtSettle,
      });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const confirmBid = useCallback(async (req: ConfirmBidReq) => {
    try {
      await marketApi.confirmBid(req);
    } catch (error) {
      handleError(error);
    }
  }, []);

  const cancelListing = useCallback(async (req: CreatePutOffReq) => {
    try {
      const res = await marketApi.createPutOff(req);
      const signedPsbt = await unisatUtils.signPsbt(res.psbt);
      await marketApi.confirmPutOff({ auctionId: req.auctionId, psbt: signedPsbt });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const getListings = useCallback((filter: any, sort: any, start: number, limit: number) => {
    return marketApi.listBrc20({ filter, sort, start, limit });
  }, []);

  const getMarketInfo = useCallback((tick: string) => {
    return marketApi.getBrc20Info(tick);
  }, []);

  const value = {
    isInstalled,
    isConnected,
    address,
    pubkey,
    connect,
    disconnect,
    signMessage: unisatUtils.signMessage,
    signPsbt: unisatUtils.signPsbt,
    getPublicKey: unisatUtils.getPublicKey,
    getBalance: unisatUtils.getBalance,
    inscribeTransfer: unisatUtils.inscribeTransfer,
    signedMessage,
    createListing,
    createBid,
    confirmBid,
    cancelListing,
    getListings,
    getMarketInfo,
  };

  return <UnisatContext.Provider value={value}>{children}</UnisatContext.Provider>;
}

export default UnisatProvider;